
@use '~@stamhoofd/scss/base/variables' as *;

.st-gradient-background {
    pointer-events: none;
    position: absolute;
    height: 1000px;
    width: 100%;
    z-index: 0;

    background: linear-gradient(180deg, rgba($color-primary-original, 0.1), rgba($color-primary-original, 0) 100%);

    .pattern {
        display: grid;
        grid-template-columns: auto 1fr;
        opacity: 0.03;

        svg {
            max-width: 100%;
            height: auto;
        }

        > .fill-remaining {
            background: linear-gradient(180deg, $color-primary-original 0%, rgba($color-primary-original, 0) 100%);
        }
    }
}

